import React from "react";
import Profile from "../components/Profile/Profile";

const ProfilePage: React.FC = () => {
  return (
    <>
      <Profile />
    </>
  );
};

export default ProfilePage;
