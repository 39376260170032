import React from "react";
import Profile from "../components/Profile/Profile";
import IntakeFiles from "../components/IntakeFiles/IntakeFiles";

const IntakeFilesPage: React.FC = () => {
  return (
    <>
      <IntakeFiles />
    </>
  );
};

export default IntakeFilesPage;
